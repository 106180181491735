import * as React from 'react';
import './Footer.styl';

export interface FooterProps {
}

export default class Footer extends React.Component<FooterProps, any> {
  public render() {
    return (
      <div className="footer">
        <div className="container">
          <p>서울시 마포구 월드컵로 128-4, 2층(성산동)</p>
          <p>사무전화: 02-324-2142 | 문의: <a href="mailto:vocieearp@gmail.com">vocieearp@gmail.com</a></p>
          <p>비영리 민간단체 고유번호: 616-82-92276 | 대표: 김철</p>
          <p>© 2019 <a href="/">동아시아 화해와 평화의 목소리</a> All Right Reserved.</p>
        </div>
      </div>
    );
  }
}
