import * as React from 'react';
import classNames from 'classnames';
import axios from 'axios';
import ShareElement from '../modules/ShareElement';
import ImpPayment from '../modules/ImpPayment';
import PaymentModel from '../models/PaymentModel';
import * as copy from 'copy-to-clipboard';
import './Donation.styl';
const BannerImage = require('../../assets/images/sitesnsbanner2.jpg');

enum DonationMethodes {
  Card,
  Account,
  PayPal
}

const createBanner = () => {
  return (
    <div className="banner">
      <div className="background">
        <img src={BannerImage} alt="제국의 위안부 소송 지원 배너"/>
      </div>
    </div>
  );
}

export interface DonationProps {
}

export interface DonationState {
  telText1: string;
  telText2: string;
  telText3: string;
  price: number;
  email: string;
  isValidPrice: boolean;
  inputMessage: string;
  submitMessage: string;
  enableSubmitInvalidAnimation: boolean;
  donationMethod: DonationMethodes; 
}

export default class Donation extends React.Component<DonationProps, DonationState> {
  Share: ShareElement;
  BuyerNameDom: any;
  paymentDom1: any;
  paymentDom2: any;
  paymentDom3: any;

  constructor(props: DonationProps) {
    super(props);

    this.Share = new ShareElement();
    this.BuyerNameDom = React.createRef();
    this.paymentDom1 = React.createRef();
    this.paymentDom2 = React.createRef();
    this.paymentDom3 = React.createRef();

    this.state = {
      price: 0,
      inputMessage: "",
      telText1: "010",
      telText2: "",
      telText3: "",
      email: "",
      isValidPrice: false,
      submitMessage: "",
      enableSubmitInvalidAnimation: false,
      donationMethod: DonationMethodes.Card
    };
  }

  private inputPrice(input: number) {
    this.setState({
      price: input,
      inputMessage: "",
      isValidPrice: true
    });
  }

  private directInputPrice(input: number | string) {
    let num: number = 0;
    if (typeof input === "string") {
      try {
        if (input && input.length > 0) {
        } else {
          this.setState({
            price: 0
          });
          return;
        }
        num = parseInt(input);
      } catch (error) {
        return;
      }
    } else if (typeof input === "number") {
      num = input;
    }

    let state: any = {};
    if (num >= 5000) {
      state["price"] = num;
      state["inputMessage"] = "";
      state["isValidPrice"] = true;
      if (num % 1000 > 0) {
        state["inputMessage"] = "1천원 단위로 가능합니다.";
        state["isValidPrice"] = false;
      }
      if (num > 500000) {
        state["inputMessage"] = "30만원 이상의 후원은 후원계좌를 활용해주세요.";
        state["isValidPrice"] = false;
      }
    } else {
      state["price"] = num;
      state["inputMessage"] = "5천원 이상 입력 가능합니다.";
      state["isValidPrice"] = false;
    }

    this.setState(state);
  }

  private onClickDonationList(donationMethod: DonationMethodes) {
    this.setState({
      donationMethod: donationMethod
    });
  }

  private copy(dom: any) {
    copy(dom.value);
    alert("복사 완료\n" + dom.value);
  }

  public render() {
    return (
      <div className="donation-contorl">
        {createBanner()}
        <div className="content">
          <div className="container">
            <div className="account">
              <div className="header">
                <h2>후원 신청</h2>
                <h1>
                  제국의 위안부 소송 지원
                </h1>
                <div className="description note">
                  <p><span>"제국의 위안부"는 </span> <span>고소인과 비판자들이 주장하는 </span>
                    <span>그런 책이 아닙니다. </span></p>
                  <p><span>5년 이상 이어진 재판에서 </span> <span>박유하교수가 해방되고, </span> <span>"제국의 위안부"가 온전한 모습으로 </span> <span>세상에 다시 나올 수 있도록 </span> <span>힘을 모아 주시기 바랍니다.</span></p>
                </div>
                <div className="description">
                  <div>
                    <span>지원 후원 호소문 자세히 읽어보기</span>
                  </div>
                  <div className="complain-container">
                    <div className="complain">
                      <a href="https://parkyuha.org/archives/6415" target="_blank">한국어</a>
                    </div>
                    <div className="complain">
                      <a href="https://parkyuha.org/archives/6420" target="_blank">English</a>
                    </div>
                    <div className="complain">
                      <a href="https://parkyuha.org/archives/6418" target="_blank">日本語</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="category">
                <ul className="nav">
                  <li onClick={e => this.onClickDonationList(DonationMethodes.Card)}>
                    <a className={classNames("nav-link", {
                      "active": this.state.donationMethod == DonationMethodes.Card
                    })}>카드 후원</a>
                  </li>
                  <li onClick={e => this.onClickDonationList(DonationMethodes.Account)}>
                    <a className={classNames("nav-link", {
                      "active": this.state.donationMethod == DonationMethodes.Account
                    })}>계좌 후원</a>
                  </li>
                  <li onClick={e => this.onClickDonationList(DonationMethodes.PayPal)}>
                    <a className={classNames("nav-link", {
                      "active": this.state.donationMethod == DonationMethodes.PayPal
                    })}>Paypal 후원</a>
                  </li>
                </ul>
              </div>
              {this.state.donationMethod == DonationMethodes.Card && <div className="form donation-card">
                <div className="form-row">
                  <h2>후원/카드 후원</h2>
                </div>
                <div className="form-row">
                  <div className="info">후원할 금액을 선택하세요.</div>
                  <div className="select-price">
                    <button className={classNames({ "active": this.state.price == 10000 })} onClick={e => this.inputPrice(10000)}>1만원</button>
                    <button className={classNames({ "active": this.state.price == 30000 })} onClick={e => this.inputPrice(30000)}>3만원</button>
                    <button className={classNames({ "active": this.state.price == 50000 })} onClick={e => this.inputPrice(50000)}>5만원</button>
                    <button className={classNames({ "active": this.state.price == 100000 })} onClick={e => this.inputPrice(100000)}>10만원</button>
                    <button className={classNames({ "active": this.state.price == 300000 })} onClick={e => this.inputPrice(300000)}>30만원</button>
                    <button className={classNames({ "active": this.state.price == 500000 })} onClick={e => this.inputPrice(500000)}>50만원</button>
                    <button className={classNames({ "active": this.state.price == 1000000 })} onClick={e => this.inputPrice(1000000)}>100만원</button>
                  </div>
                </div>
                <div className="form-row">
                  <div className="info">후원자 개인정보를 입력해주세요.</div>
                  <div className="direct-input">
                    <div className="title"><span>후원자 이름</span><span className="sub">(필수)</span></div>
                    <div><input ref={this.BuyerNameDom} placeholder="이름" type="text" step="1000"></input></div>
                  </div>
                  <div className="direct-input">
                    <div className="title"><span>후원자 휴대전화번호</span><span className="sub">(필수)</span></div>
                    <div>
                      <input className="tel1" onChange={e => this.setState({ telText1: e.target.value })} type="text" value={this.state.telText1}></input>
                      <input className="tel2" onChange={e => this.setState({ telText2: e.target.value })} type="text" value={this.state.telText2} placeholder="1234"></input>
                      <input className="tel3" onChange={e => this.setState({ telText3: e.target.value })} type="text" value={this.state.telText3} placeholder="5678"></input>
                    </div>
                  </div>
                  <div className="direct-input">
                    <div className="title"><span>후원자 email</span><span className="sub">(선택)</span></div>
                    <div>
                      <input placeholder="email" onChange={e => this.setState({ email: e.target.value })} type="text" value={this.state.email}></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="description">※ 하나, 외환계열 카드는 후원이 불가능합니다.</div>
                  <div className="description">
                    <span>※ "동아시아 화해와 평화의 목소리"는 </span> <span>비영리 임의단체로 </span> <span>기부금 영수증을 </span> <span>발행할 수 없습니다. </span>
                  </div>
                </div>
                <div className="form-row payment">
                  <div className="submit">
                    <button 
                      className={classNames({
                        "invalid": this.state.enableSubmitInvalidAnimation
                      })}
                      onAnimationEnd={e => this.onAnimationEndSubmit()} 
                      onClick={e => this.sumit()}>후원하기</button>
                  </div>
                  <div className={
                      classNames({
                        "invalid-submit-message": true,
                        "animated": this.state.enableSubmitInvalidAnimation
                      })
                    }><p>{this.state.submitMessage}</p></div>
                  <div className="private">
                    {/* <p><span>"동아시아 화해와 평화의 목소리"는 </span></p> */}
                    <p><span><a href="https://opengov.seoul.go.kr/sanction/16733414" target="_blank">「기부금품의 모집 및 사용에 관한 법률」</a></span></p>
                    <p><span>제4조 제1항에 따라 </span> <span>비영리 목적의 후원을 받고 있습니다.</span></p>
                  </div>
                  <div className="private">
                    <a href="/private" target="_blank">개인정보처리방침</a>
                  </div>
                </div>
              </div>}
              {this.state.donationMethod == DonationMethodes.Account && <div className="form">
                <div className="form-row">
                  <h2>후원/계좌 후원</h2>
                </div>
                <div className="form-row">
                  <div className="info">국민은행</div>
                </div>
                <div className="direct-input">
                  <div className="title">국민은행/동아시아 화해와 평화의 목소리</div>
                  <input ref={this.paymentDom1} type="text" value="347801-04-136441" />
                  <button onClick={e => this.copy(this.paymentDom1.current)}>복사하기</button>
                </div>
                <div className="direct-input">
                  <div className="title">국민은행 swift code (해외용)</div>
                  <input ref={this.paymentDom2} type="text" value="CZNBKRSEXXX" />
                  <button onClick={e => this.copy(this.paymentDom2.current)}>복사하기</button>
                </div>
                <div className="form-row payment">
                  <div className="private">
                    {/* <p><span>"동아시아 화해와 평화의 목소리"는 </span></p> */}
                    <p><span><a href="https://opengov.seoul.go.kr/sanction/16733414" target="_blank">「기부금품의 모집 및 사용에 관한 법률」</a></span></p>
                    <p><span>제4조 제1항에 따라 </span> <span>비영리 목적의 후원을 받고 있습니다.</span></p>
                  </div>
                  <div className="private">
                    <a href="/private" target="_blank">개인정보처리방침</a>
                  </div>
                </div>
              </div>}
              {this.state.donationMethod == DonationMethodes.PayPal && <div className="form">
                <div className="form-row">
                  <h2>후원/Paypal 후원</h2>
                </div>
                <div className="form-row">
                  <div className="info">아래의 Paypal 계정으로 보내주세요.</div>
                </div>
                <div className="direct-input">
                  <div className="title">Paypal (해외용)</div>
                  <input ref={this.paymentDom3} type="text" value="leejunghee2014@gmail.com" />
                  <button onClick={e => this.copy(this.paymentDom3.current)}>복사하기</button>
                </div>
                <div className="form-row payment">
                  <div className="private">
                    {/* <p><span>"동아시아 화해와 평화의 목소리"는 </span></p> */}
                    <p><span><a href="https://opengov.seoul.go.kr/sanction/16733414" target="_blank">「기부금품의 모집 및 사용에 관한 법률」</a></span></p>
                    <p><span>제4조 제1항에 따라 </span> <span>비영리 목적의 후원을 받고 있습니다.</span></p>
                  </div>
                  <div className="private">
                    <a href="/private" target="_blank">개인정보처리방침</a>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="share">
          <div className="title">🎤후원 알리기</div>
          <div className="buttons">
            <div>
              <button className="sms" onClick={e => this.Share.sendSMS()}>💬 SMS로 알리기</button>
            </div>
            <div>
              <button className="kakao" onClick={e => this.Share.sendKakao()}>카카오톡으로 알리기</button>
            </div>
            <div className="facebook">{this.Share.createFacebook()}</div>
          </div>
        </div>
      </div>
    );
  }
  
  onAnimationEndSubmit(): void {
    this.setState({
      enableSubmitInvalidAnimation: false
    });
  }

  isValid() {
    if (this.state.isValidPrice == false) {
      this.setState({
        submitMessage: "후원하고자 하는 금액을 선택해주세요.",
        enableSubmitInvalidAnimation: true
      });
      return false;
    }

    if (!(typeof this.BuyerNameDom.current.value != "undefined" && this.BuyerNameDom.current.value)) {
      this.setState({
        submitMessage: "이름을 다시 확인하세요",
        enableSubmitInvalidAnimation: true
      });
      return false;
    }

    if (!(typeof this.state.telText1 != "undefined" && this.state.telText1)) {
      this.setState({
        submitMessage: "전화번호를 다시 확인하세요.",
        enableSubmitInvalidAnimation: true
      });
      return false;
    }
    if (!(typeof this.state.telText2 != "undefined" && this.state.telText2)) {
      this.setState({
        submitMessage: "전화번호를 다시 확인하세요.",
        enableSubmitInvalidAnimation: true
      });
      return false;
    }
    if (!(typeof this.state.telText3 != "undefined" && this.state.telText3)) {
      this.setState({
        submitMessage: "전화번호를 다시 확인하세요.",
        enableSubmitInvalidAnimation: true
      });
      return false;
    }

    return true;
  }

  private sumit(): void {
    if (this.isValid() == false) {
      return;
    }

    let model = new PaymentModel();
    model.name = "주문명:목소리 일시 후원";
    model.amount = this.state.price;
    model.buyerName = this.BuyerNameDom.current.value;
    model.buyerTel = `${this.state.telText1}-${this.state.telText2}-${this.state.telText3}`;
    model.email = this.state.email;

    axios.post('/api/payment/users', {
      'BillAmount': model.amount,
      "BuyerEmail": model.email,
      'BuyerTel': model.buyerTel,
      'BuyerName': model.buyerName
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    let imp = new ImpPayment();
    imp.runCredit(model, (rsp: any) => {
      if (rsp.success) {
        // const host = window.location.host;
        let merchant_uid = rsp.merchant_uid;
        let imp_uid = rsp.imp_uid;
        let paid_amount = rsp.paid_amount;
        let apply_num = rsp.apply_num;

        window.location.href = `DonationCompletePage`;
      } else {
        let msg = '결제에 실패하였습니다.';
        msg += '에러내용 : ' + rsp.error_msg;
        alert(msg);
      }
    });
  }
}
