import * as React from 'react';
import { Link } from 'react-router-dom';
import './Header.styl';

export interface HeaderProps {
}

export default class Header extends React.Component<HeaderProps, any> {
  public render() {
    return (
      <div className="header-control">
        <div className="title">
          <Link to="/">
            <div>동아시아 화해와 평화의 목소리</div>
          </Link>
        </div>
      </div>
    );
  }
}
