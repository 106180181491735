import * as React from 'react';
import { render } from 'react-dom';
import Index from './pages/Index';
import ShareElement from './modules/ShareElement';
import ImpPayment from './modules/ImpPayment';
import './boot.styl';

ShareElement.initKakao();
ImpPayment.init();

declare const module: any;

render(<Index />, document.getElementById('boot'));

if (module.hot) {
    module.hot.accept();
}