import * as React from 'react';
import MainPage from './MainPage';
import DonationPage from './DonationPage';
import DonationCompletePage from './DonationCompletePage';
import PrivatePage from './PrivatePage';
import ShareElement from '../modules/ShareElement';
import { Route, BrowserRouter as Router, Link } from 'react-router-dom';

export interface IndexProps {
}

export default class Index extends React.Component<IndexProps, any> {
  public render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/donation" component={DonationPage} />
          <Route exact path="/DonationCompletePage" component={DonationCompletePage} />
          <Route exact path="/Private" component={PrivatePage} />
        </div>
      </Router>
    );
  }
}
