import * as React from 'react';

export interface PricvatePageProps {
}

export default class PricvatePage extends React.Component<PricvatePageProps, any> {
  public render() {
    return (
      <div>
        <pre style={{whiteSpace: "pre-wrap"}}>
          {`
          개인정보수집 및 이용

          개인정보 처리방침

          동아시아 화해와 평화의 목소리(이하 ‘목소리’)는 개인정보 보호법 제 30조에 따라 목소리의 홈페이지 서비스(https://voiceofearp.kr/, 이하 “서비스”)를 이용하는 회원들의 개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리하기 위해 다음과 같이 개인정보 처리방침을 만들어 알려드립니다.

          본 개인정보 처리방침의 내용은 본 사이트를 통하여 열람이 가능하며, 더 나은 서비스 제공 및 관련 법규 등의 변경에 의해 그 내용이 변경될 수 있으므로 정기적으로 본 사이트를 방문하셔서 확인해 주시기 바랍니다.
          
          본 개인정보 처리방침은 2019. 4. 1부터 적용되었습니다.
          
          제1조 개인정보의 처리목적
          회원 가입 및 관리
          
          회원 가입 및 보류/탈퇴 의사 확인, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의여부 확인, 각종 고지사항의 전달을 목적으로 합니다.
          
          회비 결제 및 회원서비스 제공
          
          회비 및 후원금 결제, 기부금영수증 발급, 납부내역 관리, 금융거래 본인인증, 행사참여 및 활동 안내 서비스, 뉴스레터, 문자발송, 달력발송, 회원소식지 발송 등을 목적으로 합니다.
          
          제2조 개인정보의 수집항목 및 방법
          목소리는 회원가입 및 서비스 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
          
          가. 수집항목
          
            ① 필수항목 :
          
             ㆍ성명, 휴대전화번호
          
            ② 선택항목 : 이메일, 후원이유
          
          나. 수집방법 : 홈페이지, 서면신청서, 전화, 이메일, 문자, SNS
          
          다. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
             ㆍIP 주소, 쿠키, MAC 주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
          
          제3조 개인정보의 처리 및 보유기간
          목소리는 법령 또는 정보 주체로부터 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
          
          회원 또는 후원자의 개인정보는 탈퇴 시 지체 없이 파기됩니다. 단, 다음의 정보는 회원탈퇴 후에도 아래의 사유로 명시한 기간 동안 보존됩니다.
          
          가. 내부 방침에 의한 정보보유
          
          보존 근거 : 서비스 이용의 혼선 방지 및 재가입 여부 조회
          보존 기간 : 10년
          나. 관계법령에 의한 정보보유
          
          ① 후원신청 및 회비 결제 등에 관한 정보
          
          보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
          보존 기간 : 5년
          ② 회원의 불만 또는 분쟁처리에 관한 기록
          
          보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
          보존 기간 : 3년
          ③ 본인 확인에 관한 기록
          
          보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
          보존 기간 : 6개월
          ④ 기부금영수증 발급의 기록 및 보관 정보
          
          보존 근거 : 소득세법
          보존 기간 : 5년
          제4조 개인정보의 제3자 제공
          목소리는 회비를 납부한 정보주체의 개인정보를 제 1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제 17조 및 제 18조에 해당하는 경우에만 개인정보를 제 3자에게 제공합니다.
          
          목소리는 다음과 같이 개인정보를 제 3자에게 제공하고 있습니다.
          
          가. 회원서비스를 위해 전문업체에 위탁하는 경우
          
          ① 수탁기관 : (주)아임포트
          위탁내용 : 회원DB 관리 및 후원금 결제, 납입내역, 문자 및 이메일 발송
          위탁정보 : 회원정보 및 후원금 결제정보
          
          ① 수탁기관 : 금융결제원
          위탁내용 : 후원금 승인 및 정산
          위탁정보 : 은행명, 계좌번호, 예금주명, 예금주 생년월일, 후원금액
          
          ② 수탁기관 : (주)KG이니시스
          위탁내용 : 신용카드 후원금 승인 및 정산
          위탁정보 : 카드번호, 카드유효기간, 후원금액
          제5조 정보주체의 권리 의무 및 행사방법
          정보주체는 개인정보 수집에 동의하지 않을 권리가 있고, 수집된 개인정보에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.
          
          가. 개인정보 열람 요구
          
          나. 오류 등이 있을 경우 정정 요구
          
          다. 삭제 요구
          
          라. 처리 정지 요구
          
          제 1항에 따른 권리 행사는 목소리에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 목소리는 이에 대해 지체 없이 조치하겠습니다.
          
          정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 목소리는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
          
          제 1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.
          
          제6조 개인정보의 파기
          목소리는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
          
          정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고, 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
          
          개인정보 파기의 절차 및 방법은 다음과 같습니다.
          
          가. 파기 절차
          
          목소리는 파기 사유가 발생한 개인정보를 선정하고, 목소리의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
          
          나. 파기 방법
          
          목소리는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
          
          제7조 개인정보의 안전성 확보 조치
          목소리는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          
          가. 관리적 조치 : 내부관리계획 수립, 시행, 정기적 직원 교육 등
          
          나. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
          
          다. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
          
          제8조 개인정보보호 책임자
          목소리는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.
          
          목소리 개인정보 관리 책임자
          
          성명 : 박규빈
          전화번호 : 02-324-2142
          E-mail : voiceearp@gmail.com
          개인정보 보호 담당부서
          
          정보주체는 목소리의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 목소리는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
          
          제9조 개인정보 열람청구
          정보주체는 개인정보 보호법 제 35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 목소리는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
          
          개인정보 열람청구 접수, 처리 부서
          
          성명 : 박규빈
          전화번호 : 02-324-2142
          E-mail : voiceearp@gmail.com
          
          제10조 권익침해 구제방법
          정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
          
          아래의 기관은 목소리와는 별개의 기관으로서, 목소리의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
          
          개인정보보호 종합지원 포털(행정안전부 운영)
          
          소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공
          홈페이지 : www.privacy.go.kr
          전화 : 02-2100-3394
          개인정보 침해신고센터(한국인터넷진흥원 운영)
          
          소관업무 : 개인정보 침해사실 신고, 상담 신청
          홈페이지 : privacy.kisa.or.kr
          전화 : (국번 없이) 118
          주소 : 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
          개인정보 분쟁조정위원회(한국인터넷진흥원 운영)
          
          소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
          홈페이지 : privacy.kisa.or.kr
          전화 : (국번 없이) 118
          주소 : 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
          경찰청 사이버테러대응센터
          
          소관업무 : 개인정보 침해 관련 형사사건 문의 및 신고
          홈페이지 : www.netan.go.kr
          전화 : (사이버범죄) 02-393-9112, (경찰청 대표) 1566-0112
          
          [부칙]
          ① 이 개인정보 처리방침은 2019. 4. 1부터 적용됩니다.
          `}
        </pre>
      </div>
    );
  }
}
