import * as React from 'react';


declare var Kakao: any;


class ShareElement {
  constructor() {
    this.initFacebook();
  }

  private getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }

  sendSMS() {
    const deviceMode = this.getMobileOperatingSystem();
    const message = encodeURIComponent("https://voiceofearp.kr 제국의 위안부 소송 지원에 후원자가 되어주세요.");

    if (deviceMode == "Android") {
      location.href = "sms://?body=" + (message);
    } else if (deviceMode == "iOS") {
      location.href = "sms://&body=" + (message);
    } else {
      console.log("모바일 환경이 아닙니다.");
    }
  }

  static initKakao() {
    // Get the first script element on the page
    var ref = document.getElementsByTagName('script')[0];

    // Create a new script element
    var script = document.createElement('script');

    // Set the script element `src`
    script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';

    // Inject the script into the DOM
    ref.parentNode.insertBefore(script, ref);

    script.onload = () =>  {
      Kakao.init('e5b9a956c59fe3e2717b7bf1d76f2415');
    };
  }

  initFacebook() {
    const div = document.createElement("div");
    div.id = "fb-root";
    document.body.appendChild(div);

    (function (d, s, id) {
      var js: any, fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/ko_KR/sdk.js#xfbml=1&version=v3.2&appId=231635957386954&autoLogAppEvents=1';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  sendKakao() {
    Kakao.Link.sendDefault({
      objectType: 'text',
      text: decodeURIComponent("제국의 위안부 소송 지원에 후원자가 되어주세요!"),
      link: {
        mobileWebUrl: "https://voiceofearp.kr/donation",
        webUrl: "https://voiceofearp.kr/donation"
      },
      buttons: [{
        title: '후원자로 참가하기',
        link: {
          mobileWebUrl: "https://voiceofearp.kr/donation",
          webUrl: "https://voiceofearp.kr/donation"
        }
      }]
    });
  }

  createFacebook() {
    return (
      <div className="fb-share-button" data-href="https://voiceofearp.kr/donation" data-layout="button_count" data-size="large" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">공유하기</a></div>
    );
  }
}


export default ShareElement;