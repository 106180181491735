import * as React from 'react';
import { Route, BrowserRouter as Router, Link } from 'react-router-dom';
import './Main.styl';

export interface MainProps {
}

export default class Main extends React.Component<MainProps, any> {
  public render() {
    return (
      <div className="main-control">
        <div className="banner">
          <div className="background"></div>
          <div className="container center">
            <div className="info">
              <h2>제국의 위안부 소송 - 후원</h2>
              <h3>
                <p>민족이나 좌우 이념의 차이를 넘어</p><br />
                <p>체념과 혐오를 넘어서기 위한</p><br />
                <p>시민의로서의 노력이 필요합니다.</p><br />
              </h3>
              {/* <Link to="/donation">
                <button className="donate-button">후원자로 참가하기</button>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="content center">
          <div className="title">
            활동 기록
          </div>
          <div className="row row1">
            <a href="https://comportwomenoftheempire.kr/" target="_blank">
              <div className="image">
              </div>
              <div className="content">
                <h2>
                  기부금 모집 보고
                </h2>
                <div className="time">
                  2021.01.10.
              </div>
              </div>
            </a>
          </div>
          <div className="row row2">
            <div className="image">
            </div>
            <div className="content">
              <h2>
                용산! 걸으며 생각하며!
              </h2>
              <div className="time">
                2018.10.20.
              </div>
            </div>
          </div>
          <div className="row row3">
            <div className="image">
            </div>
            <div className="content">
              <h2>
                한국-일본지부 교류회
              </h2>
              <div className="time">
                2018.08.01.
              </div>
            </div>
          </div>
          <div className="row row4">
            <a href="https://voiceofearp.github.io/website/books/%EC%A0%9C%EA%B5%AD%EC%9D%98%EC%9C%84%EC%95%88%EB%B6%80/" target="_blank">
              <div className="image">
              </div>
              <div className="content">
                <h2>
                  제국의 위안부 웹버전 읽기
                </h2>
              </div>
            </a>
          </div>
          <div className="row row5">
            <a href="https://parkyuha.org/lawsuits" target="_blank">
              <div className="image">
              </div>
              <div className="content">
                <h2>
                  재판 경과 알아보기
                </h2>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
