import * as React from 'react';
import './Newsletter.styl';

export interface NewsletterProps {
}

export default class Newsletter extends React.Component<NewsletterProps, any> {
  public render() {
    return (
      <div className="newsletter-control">
        <div className="container">
          <div className="title">뉴스레터</div>
          <div className="info">활동, 행사, 교육 등의 정보를 구독하세요!</div>
          <div className="subscribe">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2YvWUR6vGtfY8wlONLRXZ86w3XDrJEjjSF3Jt-ad7ZtR6rg/viewform" target="_blank">
              <button>구독하기</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
