import * as React from 'react';
import "../styles/common.styl";
import Header from '../components/Header';
import Footer from '../components/Footer';

export interface DonationCompletePageProps {
}

export default class DonationCompletePage extends React.Component<DonationCompletePageProps, any> {
  public render() {
    return (
      <div>
        <Header />
        <div style={{ textAlign: "center", marginTop: 60 }}>
          <div>후원해주셔서 감사합니다.</div>
          <div style={{ marginTop: 20 }}>
            <a href="https://comportwomenoftheempire.kr/" >
              <button className="primary-button">
                지원 후원 호소문 다시 읽어보기
              </button>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
