import PaymentModel from '../models/PaymentModel';


declare var IMP: any;
declare var window: any;


class ImpPayment {
  static init() {
    ImpPayment.initJquery();
  }

  private static initJquery() {
    var ref = document.getElementsByTagName('script')[0];
    // Create a new script element
    var script = document.createElement('script');
    // Set the script element `src`
    script.src = 'https://code.jquery.com/jquery-1.12.4.min.js';
    // Inject the script into the DOM
    ref.parentNode.insertBefore(script, ref);

    script.onload = () => {
      ImpPayment.initImpLib();
    };
  }

  private static initImpLib() {
    var ref = document.getElementsByTagName('script')[0];
    // Create a new script element
    var script = document.createElement('script');
    // Set the script element `src`
    script.src = 'https://cdn.iamport.kr/js/iamport.payment-1.1.7.js';
    // Inject the script into the DOM
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      var IMP = window.IMP; // 생략가능
      IMP.init('imp41706160'); // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
      console.log(IMP);
    };
  }

  test() {
    IMP.request_pay({
      pg: 'html5_inicis', // version 1.1.0부터 지원.
      pay_method: 'card',
      merchant_uid: 'merchant_' + new Date().getTime(),
      name: '주문명:목소리 일시 후원',
      amount: 10,
      buyer_email: 'huinalam@gmail.com',
      buyer_name: '박규빈',
      buyer_tel: '010-5556-8560',
      m_redirect_url: 'https://voiceofearp.kr/DonationCompletePage'
    }, (rsp: any) => {
      if (rsp.success) {
        var msg = '결제가 완료되었습니다.';
        msg += '고유ID : ' + rsp.imp_uid;
        msg += '상점 거래ID : ' + rsp.merchant_uid;
        msg += '결제 금액 : ' + rsp.paid_amount;
        msg += '카드 승인번호 : ' + rsp.apply_num;
      } else {
        var msg = '결제에 실패하였습니다.';
        msg += '에러내용 : ' + rsp.error_msg;
      }
      alert(msg);
    });
  }

  runCredit(model: PaymentModel, raiseResponse: any) {
    IMP.request_pay({
      pg: 'html5_inicis', // version 1.1.0부터 지원.
      pay_method: 'card',
      merchant_uid: 'merchant_' + new Date().getTime(),
      name: model.name,
      amount: model.amount,
      buyer_email: model.email,
      buyer_name: model.buyerName,
      buyer_tel: model.buyerTel,
      m_redirect_url: 'https://voiceofearp.kr/DonationCompletePage'
    }, (rsp: any) => {
      if (raiseResponse) {
        raiseResponse(rsp)
      }
    });
  }
}


export default ImpPayment;  